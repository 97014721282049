.MAO_body {
    max-width: 1212px;
    height: auto;
    margin: auto;
    margin-top: 102px;
    display: flex;
    padding-bottom: 124px;
}

.MAO_frame_parent {
    margin-top: 50px;
    display: flex;
}

.MAO_left_menu {
    max-width: 790px;
    height: auto;
}

.back_to_basket {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.ArrowBackBasketFrame {
    display: flex;
    width: 16px;
    height: 16px;
    padding: 6.667px 6.667px 6.667px 5.333px;
    justify-content: center;
    align-items: center;
    gap: 6.667px;
}

.ArrowBackBasket {
    background-image: url(../../../public/ArrowBackBasket.jpg);
    width: 5.744px;
    height: 10.667px;
    background-position: center;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: 15px;
}

.text_back_to_busket {
    color: var(--Gray-4, #797C81);
    font-family: Gilroy;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 15.6px */
    text-transform: uppercase;
    margin-left: -5px;
}

.MAO_if_noauth {
    display: flex;
    max-width: 790px;
    height: auto;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 234px;
    border-radius: 15px;
    background: var(--Gray-1, #F1F3F4);
}

.text_noauth {
    color: var(--Black, #1A1A1A);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 18.2px */
    text-transform: uppercase;
    margin-left: 20px;
}

.MAO_login_in {
    display: flex;
    width: 143px;
    height: 58px;
    flex-direction: column;
    align-items: center;
    border-radius: 7px;
    background: var(--Black, #1A1A1A);
    color: var(--White, #FEFEFE);
    text-align: right;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 18.2px */
    text-transform: uppercase;
    padding: 20px 25px;
    border: none;
    margin-right: 20px;
    cursor: pointer;
}

.MAO_right_menu {
    height: 200px;
    margin-left: 45px;
}

.MAO_contacts_body {
    max-width: 790px;
    height: 220px;
    margin-top: 40px;
}

.MAO_contacts_text_body {
    display: flex;
    cursor: default;
}

.MAO_steps {
    display: flex;
}

.MAO_text_h1 {
    color: var(--Black, #1A1A1A);
    font-family: Gilroy;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 35.2px */
    text-transform: uppercase;
}

.MAO_steps {
    color: var(--Black, #1A1A1A);
    font-family: Gilroy;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 35.2px */
    text-transform: uppercase;
    margin-left: auto;
}

.MAO_step3 {
    color: var(--Gray-2, #E5E6E8);
    font-family: Gilroy;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
    margin-left: 10px;
}

.MAO_imputs_frame {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap:20px;
}

.MAO_input_text {
    border-radius: 7px;
    color: var(--Gray-4, #797C81);
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    /* 15.6px */
    text-transform: uppercase;
}

.d-flex {
    display: flex;
    gap: 20px;
}

.MAO_input {
    border-radius: 7px;
    display: flex;
    width: 240px;
    height: 44px;
    padding: 0px 0px 0px 10px;
    align-items: center;
    align-self: stretch;
    color: #000;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    /* 18.9px */
    background: var(--Gray-1, #F1F3F4);
    border: none;
    margin-top: 5px
}

.MAO_if_noauth1 {
    display: none;
}