/*  */

.est{
    max-width: 220px;
    max-height: 315px;
    min-width: 100px;
    min-height: 100px;
}

.chelka1 {
    display: flex;
    margin-top: 40px;
    align-items: center;
    max-width: 1206px;
    /* margin-left: 8%;
    margin-right: 8%; */
    margin: auto;
}

.vset {
    margin-left: 25px;
}

.component_0,.component_1,.component_2,.component_3,.component_4,.component_5,.component_6,.component_7 {
    display: flex;
    justify-content: center;
    text-align: center;
    vertical-align: center;
    height: auto;
    width: auto;
    margin-top: 90px;
}

.m_component_0 {
    margin-top: 50px;
}

.m_component_1 {
    margin-top: 50px;
}

.component_2 {
    margin-top: 100px;
}

.m_component_2 {
    margin-top: 50px;
}

.component_3 {
    margin-top: 70px;
}

.m_component_3 {
    margin-top: 20px;
}

.component_4 {
    margin-top: 77px;
}

.m_component_4 {
    margin-top: 20px;
}

.component_5 {
    margin-top: 85px;
}

.m_component_5 {
    margin-top: 20px;
}

.component_6 {
    margin-top: 80px;
}

.m_component_6 {
    margin-top: 10px;
}

.component_7 {
    margin-top: 70px;
}

.vsetStrelka {
    background-image: url(../../static/vsetStrelka.png);
    width: 15px;
    height: 15px;
    background-size: 8px;
    background-repeat: no-repeat;
    vertical-align: center;
    background-position: center center;
    margin-left: 12px;
    margin-top: 1px;
}

.atovar1 {
    cursor: pointer;
    display: flex;
    border: NONE;
    padding: 13px;
    border-radius: 10px;
    color: #ffffff;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    background-color: #414141;
    width: 170px;
    height: 42px;
    margin-left: auto;
}

.popular2 {
    font-family: Gilroy;
    font-size: 20px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0em;
    font-family: Gilroy;
    text-align: left;
}

.list-group {
    background-color: #282828;
    height: auto;
    margin-top: 4%;
    border-radius: 0%;
    color: #FFFFFF;
    color: #FFF;
    font-family: Gilroy;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    padding: 0 0 165px 0px;
}


.pop {
    display: flex;
    flex-wrap: wrap;
    max-width: 1206px;
    margin: auto;
    gap: 20px;
    margin-top: 48px;
}

.list-group-item {
    width: 222px;
    height: 270px;
    border-radius: 13px;
    background: #282828;
    border: 2px solid #666C77;
    /* margin-left: 7px; */
    margin-top: 7px;
    padding: 30px;
    color: #FFFFFF;
}

.list-group-item+.list-group-item {
    border-top-width: 2px;
}

.atovar1:hover {
    background-color: #0071E3;
    transition: all 0.5s;
}

.list-group-item:first-child {
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
}

.list-group-item:last-child {
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
}

.list-group-item:hover {
    border: 2px solid #0071E3;;
}