.cart_header {
    color: #BFC2C7;
    margin-bottom: 30px;
    font-family: Gilroy;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.cart_header_mid {
    color: black;
}