.block{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.block:hover > .catalogTypesStock{
    display: flex;
}

.typeHeader{
    font-size: 36px;
    color: var(--color-black);
}

.catalogTypesStock{
    display: none;
    /* width: 150px; */
    margin-left: 56px;
}

.catalogTypesStockPoint {
    margin-top: 4px;
    margin-right: 7px;
    top: 80px;
    left: 320px;
    border-radius: 50%;
    background-color: #55bc72;
    width: 8px;
    height: 8px;
} 

.catalogTypesStockText {
    top: 77px;
    left: 335px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--color-black);
}

.catalogTypesBlock {
    width: 500px;
    display: none;
    margin-left: 45px;
    color: var(--color-dimgray);
}

.catalogTypesBlockElements{
    display: block;
    
}


.catalogTypesElement:hover{
    cursor: pointer;
    color: #0071E3;
}
/* 
.catalogTypesElement{
    width: 300px;
} */




.catalog {
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    width: 936px;
    height: 790px;
}
