.search_page {
    font-family: Gilroy;
    /* width: 95%; */
    max-width: 1250px;
    margin: auto;
    margin-top: 141px;
    /* display: flex; */
    /* flex-direction: row; */
}

.blocknameelem {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
    margin-left: 100px;
    justify-content: center;
}

.mobileFilterSelect{
    position: absolute;
    top: 75px;
    width: 100%;
    height: 100%;
    background: #252525;
}

.vart_block {
    /* max-width: 1200px; */
    margin-top: 55px;
    display: flex;
    flex-direction: row;
    
}

.chechboxes_block {
    display: block;
    flex-direction: column;
}

.loadBut_block{
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.load_button{
    background: rgba(0, 113, 227, 1);
    border: none;
    height: 32px;
    font-family: Gilroy;
    border-radius: 4px;
    padding: 0 40px;
    color: white;
}

.buttonsBlock{
    
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    height: 70%;
    overflow-y: auto;
  }
  
  .backButtonfilters{
    margin-bottom: 20px;
    padding: 24px 16px 0 ;
    font-family: Gilroy;
    font-size: 40px;
    color: #FFFFFF;
    cursor: pointer;
  }

  .buttonsFiltersBlock{
    height: 100px;
    width: auto;
  }
  