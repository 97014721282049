.oror {
    background-image: url(../../static/bigstar.png);
    height: 240px;
    width: 240px;
    display: flex;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
    background-size: cover;
    font-size: 64;
}

.cb {
    color: #000;
    font-family: Gilroy;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.device_page_favorite_block {
    margin: auto;
    /* width: 130px; */
    display: flex;
    /* margin-left: 157px; */
    cursor: pointer;
}

.agn {
    align-items: center;
    display: flex;
    text-align: left;
}

.reddownload {
    color: var(--element-red, #E30613);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.28px;
    text-transform: uppercase;
    margin-left: 85px;
    cursor: pointer;
}

.bka {
    color: #000;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 20px;
}

.greytext {
    color: #656565;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 20px;
}

.dflexand {
    cursor: pointer;
}

.pdf {
    color: #A7AEB5;
    text-align: center;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.28px;
    text-transform: uppercase;
    margin-left: 3px;
}

.agn1 {
    color: #000;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-left: 10px;
}

.bka1 {
    text-decoration: underline;
    color: #000;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-left: 3px;
    margin-top: 20px;
}

.lastprice {
    color: var(--Black, #1A1A1A);
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: normal;
    text-decoration-line: strikethrough;
    text-transform: uppercase;
    text-decoration: line-through;
}

.newprice {
    color: var(--Black, #1A1A1A);
    font-family: Gilroy;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: normal;
}

.harakter {
    color: var(--Black, #1A1A1A);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 18.2px */
    text-transform: uppercase;
}

.vkorzinu {
    /* width: 225px; */
    border-radius: 7px;
    background-color: #0071E3;
    color: #FFF;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 18.2px */
    text-transform: uppercase;
    padding: 22px;
    border: none;
    cursor: pointer;
}

.NetVNal {
    width: 100%;
    /* width: 225px; */
    height: 60px;
    align-items: center;
    border-radius: 7px;
    color: black;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;
    /* padding: 22px; */
    border: none;
    display: flex;
    justify-content: center;
    background-color: rgb(200, 12, 12);
    cursor: default;
}

.vkorzine {
    /* width: 225px; */
    border-radius: 7px;
    /* background-color: #0071E3; */
    color: blue;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 18.2px */
    text-transform: uppercase;
    padding: 22px;
    border: none;
    display: flex;
    justify-content: center;
    background-color: #F1F3F4;
}

.svgL {
    margin-left: 15px;
    margin-top: -1px;
}

.izbr {
    color: var(--Black, #1A1A1A);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;
    width: 108px;
    margin-left: -14px;
}

.dhg {
    display: flex;
    align-items: center;
}

.assh {
    width: -webkit-fill-available;
    height: auto;
    font-size: 32;
    border: 12px;
    background-color: #F1F3F4;
    padding: 20px;
    display: block;
    align-items: center;
    justify-content: space-around;
    /* margin-left: 100px; */
    border-radius: 20px;
    /* margin-left: auto; */
}

.alltovarcard {
    max-width: 1211px;
    display: flex;
    margin: auto;
    margin-top: 158px;
    margin-bottom: 150px;
}

.download_button {
    margin-bottom: 70px;
}

.fav_act_heart {
    height: 20px;
    width: 20px;
    margin-top: -1px;
}

.devcie_page_fav_button {
    display: flex;
    /* width: 200px; */
}

.loading {
    width: 540px;
    height: 545px;
    display: block;
    border-radius: 20px;
    margin-left: auto;
    background: linear-gradient(90deg, #ff0000, #00ff00, #0000ff);
    /* Градиентный переход */
    background-size: 200% 200%;
    /* Размер фона */
    animation: gradientShift 2s infinite;
    /* Анимация */
}

@keyframes gradientShift {
    0% {
        background-position: 0% 50%;
        /* Начальное положение градиента */
    }

    50% {
        background-position: 100% 50%;
        /* Положение градиента на середине анимации */
    }

    100% {
        background-position: 0% 50%;
        /* Конечное положение градиента */
    }
}