.openedMenuButton{
    display: flex;
    /* justify-content: space-between; */
    cursor: pointer;
    padding: 20px 0;
    font-weight: 600;
    line-height: 20px;
    color: #FFFFFF;
    font-family: Gilroy;
    font-size: 14px;
  }
  .backButton{
    padding: 24px 16px 0 ;
    font-family: Gilroy;
    font-size: 14px;
    color: #A7AEB5;
    cursor: pointer;
  }
  
  
  .devider{
    width: 100%;
    height: 1px;
    background-color: #FFFFFF;
    opacity: 15%;
  }

  .vectorIcon1{
  height: 20px;
    width: 20px;
    position: relative;
  }