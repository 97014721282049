.type_preview_block{
    width: auto;
    /* margin-bottom: -50px; */
    /* margin-top: 150px; */
}

.type_preview_block_text {
    color: #000;
    font-family: Gilroy;
    font-size: 36px;
    font-weight: 700;
}

.type_path {
    color: #717171;
    font-size: 15px;
    font-weight: 500;
}

.type_preview_subtype_elements {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 80px;
    row-gap: 40px;
}

.type_preview_subtype_element{
    width: 300px;
}

.type_preview_subtype_text {
    width: 260px;
    color: #0071E3;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 500;
    text-decoration-line: none;
}

.type_preview_subtype_text:hover {
    cursor: pointer;
    text-decoration-line: underline;
}

.type_preview_subtype_num {
    color: #717171;
}

.type_preview_block_selected_params{
    margin-top: 30px;
    gap: 30px;
    display: flex;
}

.selected_param{
    display: flex;
    flex-direction: row;
}

.param_text{
    width: auto;
}

.selected_param_block {
    padding: 0 48px 0 48px;
    padding-top: 5px;
    color: #FFF;
    text-align: center;
    border-radius: 5px;
    width: auto;
    height: 30px;
    background: var(--System-Blue, #0071E3);
}

.selected_param_img {
    margin-left: 10px;
    height: 9px;
    width: 9px;
    margin-top: 13.5px;
}

.selected_param_img:hover {
    filter: brightness(0);
    transition: all 0.3s;
}



.navigation_link{
    color: #717171;
}

.search_result {
    margin-left: -60px;
    padding-bottom: 20px;
}