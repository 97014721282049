.favorite_component{
    
    width: 100%;
}

.fav_elements{
    margin: auto;
    max-width: 1211px;
    margin-top: 151px;
}

.fav_items{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    width: 100%;
    gap: 15px;
}