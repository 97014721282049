.rowitems {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: auto;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;

}

.atovar12 {
    color: #0071E3;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 132px;
    height: 42px;
    flex-shrink: 0;
    border: none;
    border-radius: 6px;
    margin-left: auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.atovar12:hover {
    background-color: #0071E3;
    color:aliceblue;
    transition: all 0.5s;
}

.atovar12:hover .filter-green{
    filter: brightness(0) invert(24);
    transition: all 0.3s;
}
