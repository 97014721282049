@media (max-width: 1300px) {
    .bottomHeader {
        margin-top: 1%;
    }

    .topHeader {
        margin-left: 1% !important;
    }

    .bottomHeader * {
        margin-top: 1% !important;
    }
}

@media (max-width: 643px) {
    .search {
        margin-top: 2% !important;
        margin-left: 35px !important;
    }

    .bottomHeader {
        flex-direction: column !important;
        align-items: left !important;
    }

    .ml-custom {
        flex-direction: column;
    }

    .location {
        margin-left: -50px;
    }
}

@media (max-width: 900px) {
    .search {
        width: 303px !important;
    }

    .searchInput {
        width: 230px !important;
    }
}

@media (max-width: 700px) {
    .h2css {
        font-size: 15px;
    }
}

.Header {
    font-family: Gilroy;
    background-color: white;
    box-shadow: 0px 1px 6px 0px rgba(82, 82, 82, 0.10);
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0;
    margin: auto;
    /* margin-bottom: 134px; */
}

.topHeader {
    display: flex;
    width: auto;
    height: auto;
    padding-top: 20px;
}

.bottomHeader {
    display: flex;
    width: auto;
    flex-wrap: wrap;
    height: auto;
    padding-top: 7px;
    padding-bottom: 10px;
    color: #363636;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.3px;
}

.bottomHeader * {
    cursor: pointer;
}

.ml-custom {
    display: flex;
    flex-wrap: wrap;
    margin-left: -9px;
}

a.populars {
    margin-left: 39px;
    background-image: url(../../static/Fire.png);
    background-repeat: no-repeat;
    background-position: right;
    height: 22px;
    width: 100px;
    background-size: 15px;
}

.ml-custom * {
    margin-left: 30px;
}

.logo {
    margin-top: 8px;
    cursor: pointer;
}

.catalog_button {
    display: inline-block;
    border-radius: 5px;
    background: #0071E3;
    width: 120px;
    height: 40px;
    margin-left: 60px;
    cursor: pointer;
}

.catalog_text {
    color: #FFF;
    text-align: center;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: -20px;
    margin-left: 25px;
}

.catalog_image {
    margin-top: 13px;
    margin-left: 17px;
}

.search {
    width: 512px;
    height: 40px;
    display: flex;
    margin-left: 60px;
}

.searchInput {
    width: 487px;
    height: auto;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE+SURBVHgBnVPLkcIwDLU55ZgSvKckN7aCZSvYEoAKyFbApAKgAkIl0AEckxPugBxzSngvKBnzGwKacSRZes+ypWjliDHG9zwvhjmmy726rg9a6wPMJMsyq25Et0YYhhMkLgDwoQtsWdgF7CH3hOw/z/PlHQHBUGsCkbQsy3JlrS1ccqg5q7ol0Qga6C3APoLfj8oUEoOcfVVVCrrLG8CZCXP8DEyR2FQOmnWBKIr2QRCcVE9B/gnVHFt/ALahvHIvYVeUdKgh4IdlqQ+FBBYPYzgDPTGGGJcgZQUYoL9XSGknCXYuwabtv7T0GZixubhJR8D24AqJvMMWieMH4BFjPJ0P7ra7G2W0MuYoi2tlKXeUpXSSpSCZtldoRMbzi0HZGsli65oYQL+8MtYEVa2vKnhHAE7V5Y9NPyJwSH7OrwKYesmVidYAAAAASUVORK5CYII=);
    background-size: 15px;
    background-repeat: no-repeat;
    padding-left: 30px;
    vertical-align: center;
    background-color: #EEE;
    border-radius: 5px;
    border: 1px solid #EEE;
    box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.12) inset;
    background-position: 7px center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    font-family: 'Gilroy';
}

.searchLupa {
    border-radius: 0px 7px 7px 0px;
    width: 56px;
    height: auto;
    margin-left: -52px;
    background-color: #0071E3;
    border: none;
    background-image: url(../../static/lupa.png);
    cursor: pointer;
}

.cursorP {
    cursor: pointer;
}

.rightOptions {
    display: flex;
    margin-left: 50px;
}

.rightOptions * {
    margin-left: 11px;
}

.orders {
    background-image: url(../../static/order.png);
    background-repeat: no-repeat;
    background-position: center center;
    height: 21px;
    width: 50px;
    background-size: 20px;
    margin-left: 8px;
}

.favorits {
    background-image: url(../../static/favorite.png);
    background-repeat: no-repeat;
    background-position: center center;
    height: 21px;
    width: 50px;
    background-size: 20px;
    margin-left: 20px;
}

.basket {
    background-image: url(../../static/basket.png);
    background-repeat: no-repeat;
    background-position: center center;
    height: 21px;
    width: 50px;
    margin-left: 12px;
    background-size: 20px;
}

.margerZ {
    margin-top: 4px;
    display: flex;
}

.profile {
    background-image: url(../../static/ProfileNoPicture.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    border: 3px solid #0071E3;;
    height: 37px;
    width: 37px;
    margin-left: 30px;
    cursor: pointer;
    margin-left: 37px;
}

.location {
    display: flex;
}

.textMoscow {
    background-image: url(../../static/location.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 21.6px;
    width: 60px;
    margin-left: 0;
    background-size: 15px;
    background-repeat: no-repeat;
    padding-left: 30px;
    vertical-align: center;
    background-position: 7px center;
    padding-right: 28px;
}

.header_fav_count {
    position: absolute;
    margin-left: 47px;
    margin-top: -5px;
    width: 16px;
    height: 13px;
    flex-shrink: 0;
    border-radius: 11px;
    background: var(--system-red-01, #FC3F1D);
    color: var(--SystemWhite, #FFF);
    text-align: center;
    font-family: Gilroy;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text-padding-top {
    color: #494949;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
}

.SearchInput:focus + .searchInfo{
    display:block;
}

input:focus + div#searchInfo {
    visibility: visible;
}

.populars,.resistors,.condensators,.inductors,.processors,.preobrazovateli {
    color: #363636;
}

.asdqwe {
    width: 1206px;
    margin: auto;
}