.profile_back {
    top: 22px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    position: absolute;
    height: 300px;
    width: 1200px;

}

.profile_component {
    position: fixed;
    top: 22px;
    right: 0;
    left: 920px;
    z-index: 101;
    width: 265px;
    height: 259px;
    padding-top: 22px;
    border-radius: 22px;
    box-shadow: 0px 30px 45px 0px rgba(0, 0, 0, 0.09), 0px -30px 45px 0px rgba(0, 0, 0, 0.09);
    background: #FFF;
    margin-left: auto;
    margin-right: auto;
    /* display: block; */
}

.profile_data_block {
    width: 230px;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
}

.nickname_text {
    display: block;
    overflow: hidden;
    margin-top: 10px;
    width: 150px;
    color: #252525;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

}

.email_text {
    margin-top: 10px;
    margin-bottom: 0px;
    color: #9A9A9A;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.profile_img_block {
    margin-left: 20px;
    width: 43px;
    height: 43px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 3px solid #0071E3;
}

.profile_buttons_block {
    width: 230px;
    margin: auto;

    margin-top: 10px;
    margin-bottom: 20px;
}


.orders_button,
.settings_button,
.favorites_button {
    padding: 0;
    margin-bottom: 10px;
    border: none;
    background: white;
    color: #252525;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.profile_login_block {
    margin-top: 17px;
    width: 265px;
    height: 57px;
    display: inline-flex;
    height: 57px;
    border-radius: 0 0 22px 22px;
    /* padding: 12px 46px 12px 23px; */
    align-items: center;
    flex-shrink: 0;
    background: #282828;
}

.login_button_block {
    display: flex;
    flex-direction: row;
    height: 33px;
    width: 230px;
    margin: auto;
}

.login_button {
    width: 170px;
    height: 17px;
    margin-left: 10px;
    /* margin-bottom: 20px; */
    margin-top: auto;
    margin-bottom: auto;
    background: #282828;
    border: none;
    color: #FFF;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.logout_button {
    border: none;
    background-color: #fff;
    display: flex;
    justify-self: center;
    margin-left: 12px;
    color: #252525;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.imgExit {
    height: 18px;
    width: 18px;
    margin-left: 5px;
    margin-top: 2px;
}

.profile_nologin_block {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.need_authorization {
    color: #4F4F4F;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.sign_in_button {
    width: 219px;
    height: 37px;
    flex-shrink: 0;
    border-radius: 4px;
    background: var(--System-Blue, #0071E3);
    border: none;
    color: #FFF;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.registration_button {
    width: 219px;
    height: 37px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #D9D9D9;
    color: var(--System-Blue, #0071E3);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    cursor: pointer;
}

.profile_component1 {
    position: fixed;
    top: 22px;
    right: 0;
    left: 920px;
    z-index: 101;
    width: 265px;
    height: 225px;
    padding-top: 22px;
    border-radius: 22px;
    box-shadow: 0px 30px 45px 0px rgba(0, 0, 0, 0.09), 0px -30px 45px 0px rgba(0, 0, 0, 0.09);
    background: #FFF;
    margin-left: auto;
    margin-right: auto;
}

.mob_profile_component1 {
    position: fixed;
    top: 60px;
    left: 10px;
    z-index: 101;
    width: 265px;
    height: 225px;
    padding-top: 22px;
    border-radius: 22px;
    box-shadow: 0px 30px 45px 0px rgba(0, 0, 0, 0.09), 0px -30px 45px 0px rgba(0, 0, 0, 0.09);
    background: #FFF;
    margin-left: auto;
    margin-right: auto;
}