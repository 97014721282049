
.testrowclass{
    /* overflow-x: auto; */
}
.h2css {
    font-family: Gilroy;
    font-size: 30px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 15%;
    color: white;
}

.info1 {
    justify-content: center;
    display: flex;
    height: 382px;
    max-width: 1206px;
    width: 100%;
    border-radius: 20px;
    margin: auto;
    margin-top: 134px;
    background-color: black;
    background-image: url(../../static/bggradient.png);
    background-size:100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
}


.svg2 {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 4%;
}

.svgIcons1,.svgIcons3 {
    display: flex;
    justify-content: left;
    height: 60px;
    width: 60px;
    align-items: center;
}

.svgIcons2 {
    display: flex;
    justify-content: left;
    height: 60px;
    width: 60px;
    align-items: center;
    margin-top: 1px;
}

.svgIcons4 {
    display: flex;
    justify-content: left;
    height: 39px;
    width: 39px;
    align-items: center;
    margin-top: 7px;
}

.mrtInfo1 {
    margin-top: 7px;
}

.mrtInfo2 {
    margin-top: 7px;
}

.mrtInfo3 {
    margin-top: 7px;
}

.mrtInfo4 {
    margin-top: 18px;
}

.katalog {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 54px;
    width: 290px;   
    margin: 0 auto;
    border-radius: 60px;
    background-color: white;
    font-family: Gilroy;
    font-size: 21px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 100px;
    border: none;
    cursor: pointer;
}
.lol {
    margin-left: 17px;
}

.strelka {
        width: 44px;
        height: 44px;
        background-image: url(../../static/arrow.png);
        background-size: 15px;
        background-repeat: no-repeat;
        vertical-align: center;
        background-color: #0071E3;;
        background-position: center center;
        border-radius: 40px;
        margin-left: 14px;
}

.description {
    overflow-x: auto;
    height: auto;
    max-width: 1206px;
    margin: auto;
    display: flex;
    align-items: center;
    color: #2D2D2D;
    /* justify-content: center; */
    margin-bottom: 100px;
}

.op {
    min-width: 284px;
    font-family: 'GloryBold';
    height: 206px;
    background-color: #F8F8F8;
    padding: 20px;
    text-align: left;
    border-radius: 20px;
    font-family: Gilroy;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 40px;
    margin-left: 1%;
    border-radius: 13px;
    box-shadow: 1px 1px 13px 0px rgba(0, 0, 0, 0.13);
}

.text1 {
    color: #0071E3;
}

.text2 {
    color: #9747FF;
}

.text3 {
    color: #21A366;
}

.text4 {
    color: #FF9F47;
}

.op1 {
    min-width: 284px;
    font-family: 'GloryBold';
    height: 206px;
    background-color: #F8F8F8;
    padding: 20px;
    text-align: left;
    border-radius: 20px;
    font-family: Gilroy;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 40px;
    box-shadow: 1px 1px 13px 0px rgba(0, 0, 0, 0.13);
}