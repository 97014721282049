.PopularBody {
    max-width: 1258px;
    margin: auto;
    margin-top: -100px;
    padding-top: 100px;
}

.chelka {
    display: flex;
    max-width: 1206px;
    width: 95%;
    align-items: center;
    margin: auto;
    margin-top: -100px;
    padding-top: 100px;

}

.rowitems::-webkit-scrollbar { width: 0 !important }

.rowitems {
    gap: 15px;
    display: flex;
    overflow:hidden;
    overflow-x: scroll;
    display: flex;
    height: auto;
    /* flex-wrap: nowrap; */
    /* max-width:  1260px;
    box-sizing: border-box;
    width: 100%; */
}

.strelka1SVG {
    margin-left: 9px;
    margin-top: 2px;
}   

.alltovars {
    cursor: pointer;
    color: #0071E3;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 132px;
    height: 42px;
    flex-shrink: 0;
    border: none;
    border-radius: 6px;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.alltovars:hover {
    background-color: #0071E3;
    color:aliceblue;
    transition: all 0.5s;
}

.alltovars:hover .filter-green{
    filter: brightness(0) invert(24);
    transition: all 0.3s;
}

.tovars {
    /* background: rgb(45, 135, 247); */
    background: linear-gradient(135deg, rgba(45, 135, 247, 1) 5%, rgba(0, 114, 255, 1) 45%, rgba(53, 53, 53, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.popular1 {
    color: #353535;
    font-family: Gilroy;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.popular {
    padding: 50px;
}