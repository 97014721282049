.qweqas {
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
    color: rgb(241, 243, 244);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 18.2px */
    text-transform: uppercase;
    border: none;
    height: 45px;
    /* width: 508px; */
    background-color: rgb(241, 243, 244);;
  }
  .xz{
    margin-top: 6%;
  }
  .bgcqa {
    background-color: rgb(241, 243, 244);;
  }
  .dflexand {
    display: flex;
    background-color: rgb(241, 243, 244);;
    flex-wrap: wrap;
  }
  
  .svgd {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 70%;
    margin-top:5%;
  }
  
  .lineout {
    margin-top: 2%;
  }
  
  .harakter {
    margin-top:5%;
  }
  
  .mttop {
    margin-top:1%;
  }
  
  .infot {
    color: #656565;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  }
  
  .infod {
    color: #000;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-left:5px;
  }
  
  .td {
    display: flex;
    flex-wrap: wrap;
  }