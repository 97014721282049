.homeDesktop {
    margin-top: 35px;
    height: 458px;
    width: 1150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-family: Gilroy;
    background-color: var(--color-white);
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
}

.catalogSubdirectoryBlock {
    display: block;
    width: 375px;
    height: 393px;
}

.catalogSubdirectoryBlock_2 {
    display: block;
    width: 375px;
    height: 393px;
    margin-left: 20px;
}

.separatorIcon {
    width: 1px;
    height: 406px;
    display: block;
    margin-left: 15px;
    background: #D4D4D4;
}

.typeHeader {
    font-size: 36px;
    color: var(--color-black);
}

.catalogTypesStock {
    display: flex;
}

.catalogTypesStockPoint {
    top: 80px;
    left: 320px;
    border-radius: 50%;
    background-color: #55bc72;
    width: 8px;
    height: 8px;
}

.catalogTypesStockText {
    top: 77px;
    left: 335px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--color-black);
}

.catalog {

    /* margin-left: 50px; */
    display: flex;
    flex-direction: row;
    width: 1100px;
    height: 405px;
}


.alltovarsCatalog {
    cursor: pointer;
    color: #0071E3;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 141px;
    height: 42px;
    flex-shrink: 0;
    border: none;
    border-radius: 6px;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin-top: 380px;
}

.alltovarsCatalog:hover {
    background-color: #0071E3;
    color:aliceblue;
    transition: all 0.5s;
}

.alltovarsCatalog:hover .filtergreen{
    filter: brightness(0) invert(24);
    transition: all 0.3s;
}

.strelka1SVG {
    margin-left: 9px;
    margin-top: 2px;
}   